import * as React from "react";

import { getIsVertical } from "../utils/getIsVertical";

function Arrow({ style, fill }: { style?: React.CSSProperties, fill?: string }) {
	const isVertical = getIsVertical();

    return (
        <div style={{ height: isVertical ? "5rem" : "3rem", marginRight: "0.3rem", ...style }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 6 47" fill="none"><path fill={fill || "var(--accent-color"} d="m3 47 2.887-5H.113L3 47Zm.5-4.5V0h-1v42.5h1Z"/></svg>
        </div>
    );
}

export default Arrow;
