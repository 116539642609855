import * as React from "react";

import Arrow from "../svgComponents/Arrow";

import "./Accordion.css";

function useOnResize(
    refObject?: React.RefObject<HTMLElement>,
) {
    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        const resizeListener = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                if (refObject?.current?.firstChild instanceof Element) {
                    refObject.current.style.paddingBottom = getComputedStyle(refObject.current.firstChild).height;
                }
            }, 250);
        };

        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []);
}

interface AccordionProps {
    items: {
        Title: JSX.Element;
        Content: JSX.Element;
    }[];
    showArrow?: boolean;
    titleStyle?: React.CSSProperties;
}

const Accordion: React.FC<AccordionProps> = ({ items, showArrow = true, titleStyle }) => {
    const [openedIndex, setOpenedIndex] = React.useState(-1);

    return (
        <div>
            {
                items.map(({ Title, Content }, index) => {
                    const titleRef = React.useRef<HTMLDivElement>(null);
                    const contentRef = React.useRef<HTMLDivElement>(null);

                    React.useEffect(() => {
                        if (contentRef.current && openedIndex !== index) {
                            contentRef.current.style.paddingBottom = "0";
                        } else {
                            window.setTimeout(() => {
                                if (titleRef.current && titleRef.current.offsetTop < window.scrollY) {
                                    window.scrollTo({
                                        top: titleRef.current.offsetTop,
                                        behavior: "smooth",
                                    });
                                }
                            }, 400);
                        }
                    }, [openedIndex]);

                    const toggleItem = React.useCallback(() => {
                        const toOpen = openedIndex !== index;

                        if (contentRef.current) {
                            contentRef.current.style.paddingBottom = toOpen
                                ? contentRef.current.scrollHeight + "px"
                                : "0";
                        }

                        setOpenedIndex(toOpen ? index : -1);
                    }, [openedIndex]);

                    useOnResize(openedIndex === index ? contentRef : undefined);

                    return (
                        <div className="accordionItem" key={`accordion-item-${index}`}>
                            <div className="accordionTitle" style={titleStyle} onClick={toggleItem} ref={titleRef}>
                                {Title}
                                {showArrow ? <div style={{ transform: `scaleY(${openedIndex === index ? "-1" : 1})`, transition: "transform 0.3s", flexShrink: 0 }}>
                                    <Arrow />
                                </div> : null}
                            </div>
                            <div className={`accordionContent ${openedIndex === index ? "visible" : ""}`} ref={contentRef}>
                                {Content}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default Accordion;
