import * as React from "react";

import { VerticalContent } from "./Navigation";

import "./VerticalAccordion.css";

interface VerticalAccordionProps {
    items: {
        title: string;
        Content: JSX.Element;
    }[];
}

const VerticalAccordion: React.FC<VerticalAccordionProps> = ({ items }) => {
    const [openedIndex, setOpenedIndex] = React.useState(0);

    const toggleItem = React.useCallback((index: number) => {
        if (openedIndex !== index) {
            setOpenedIndex(index);
        }
    }, [openedIndex]);

    return (
        <div className="verticalAccordion">
            {
                items.map(({ title, Content }, index) => (
                    <div
                        key={`verticalAccordion-item-${index}`}
                        className={`verticalAccordionItem ${openedIndex === index ? "opened" : ""}`}
                        onClick={() => toggleItem(index)}
                    >
                        <div className={`verticalAccordionTitle ${openedIndex === index ? "opened" : ""}`}>
                            <VerticalContent content={title} />
                        </div>
                        <div className={`verticalAccordionContent ${openedIndex === index ? "opened" : ""}`} style={{ left: `${index * 2}rem` }}>
                            {Content}
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default VerticalAccordion;
